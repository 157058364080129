const state = {
  globalLoader: false,
  isOpenMenu: false,
  fixBody: false
}

const getters = {
  globalLoader: state => state.globalLoader,
  isOpenMenu: state => state.isOpenMenu,
  fixBody: state => state.fixBody
}

const mutations = {
  changeShowLoader (state, status) {
    state.globalLoader = status
  },
  changeShowMenu (state, status) {
    state.isOpenMenu = status
    this.commit('system/changeFixBody', status)
  },
  changeFixBody (state, status) {
    const body = document.querySelector('body')
    if (status) {
      body.classList.add('fix')
    } else {
      body.classList.remove('fix')
    }
  }
}

export default {
  state,
  getters,
  mutations
}

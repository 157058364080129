<template>
  <div class="image-input" :class="position">
    <div class="image-input__area mb-2" v-show="!fullImage">
      <p>{{ $t('uploadPhoto') }} <span>{{ $t('orDragDrop') }}</span></p>
      <input
        ref="input"
        :id="imageId"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage">
    </div>
    <section class="preview-area" v-show="!isEditImageMode">
      <div class="cropped-image">
        <img
          v-if="cropImg"
          :src="cropImg"
          alt=""
        />
        <div v-else class="crop-placeholder"/>
        <div class="image-input__actions mt-2" v-show="!disabled">
          <label :for="imageId" v-show="!isEditImageMode && cropImg">
            <v-chip
              close-icon="mdi-application-import"
              class="c-pointer"
              close
              color="primary"
              @click:close="$refs.input.click()"
              outlined
            >
              {{ $t('changeImage') }}
            </v-chip>
          </label>
        </div>
      </div>
    </section>
    <div class="content" v-show="isEditImageMode">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            :aspectRatio="aspectRatio"
            ref="cropper"
            :viewMode="2"
            dragMode="none"
            :width="150"
            :minCropBoxWidth="150"
            :minCropBoxHeight="150"
            :minContainerWidth="150"
            :minContainerHeight="150"
            :zoomable="false"
            :src="fullImage"
            preview=".preview"
          />
        </div>
      </section>
      <div class="image-input__actions mt-2">
        <label :for="imageId">
          <v-chip
            color="primary"
            close-icon="mdi-application-import"
            class="c-pointer"
            close
            @click:close="$refs.input.click()"
            outlined
          >
            {{ $t('changeImage') }}
          </v-chip>
        </label>
        <a
          href="#"
          role="button"
          @click.prevent="cropImage"
        >
          <v-chip
            close-icon="mdi-check"
            class="c-pointer"
            color="primary"
            close
            outlined
            @click:close="cropImage"
          >
            {{ $t('done') }}
          </v-chip>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ImageFile',
  props: {
    aspectRatio: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    image: {
      type: String
    },
    position: {
      type: String,
      default: 'center'
    },
    imageId: {
      type: String,
      default: 'input-image'
    },
    maxSize: {
      type: Number,
      default: 5
    }
  },
  emits: ['input'],
  data () {
    return {
      fullImage: '',
      cropImg: '',
      isEditImageMode: false
    }
  },
  components: { VueCropper },
  watch: {
    image () {
      this.fullImage = this.image
      this.cropImg = this.image
    }
  },
  created () {
    if (this.image) {
      this.fullImage = this.image
      this.cropImg = this.image
    }
  },
  methods: {
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.isEditImageMode = false
      this.$emit('input', this.cropImg)
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file && file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      const fileSizeMB = file.size / 1024 / 1024
      if (fileSizeMB > this.maxSize) {
        this.$toasted.error(this.$t('errorFileSize').replace(':value', this.maxSize))
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.fullImage = event.target.result
          this.isEditImageMode = true
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom (percent) {
      this.$refs.cropper.relativeZoom(percent)
    }
  },
  destroyed () {

  }
}
</script>
<style lang="scss">
.image-input{
  margin: 15px 0;
  .cropper-point,
  .cropper-line{
    background-color: var(--secondary);
  }
  .cropper-area{
    font-size: 0;
  }
  .cropped-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px;
    img{
      min-width: 100px;
      min-height: 100px;
      width: 100%;
      display: block;
      max-width: 300px;
      margin: 0 auto;
    }
  }
  &.left{
    .cropped-image img{
      margin: 0;
    }
    .image-input__actions{
      justify-content: flex-start;
      margin-top: 20px!important;
    }
  }
  &__preview{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    label{
      display: inline-block;
      position: relative;
      z-index: 2;
      transition: color $trans;
      &:hover{
        cursor: pointer;
        color: $black;
      }
    }
    a{
      transition: color $trans;
      margin-left: 15px;
      text-decoration: none;
      &:hover{
        cursor: pointer;
        color: $black;
      }
    }
  }
  &__area{
    position: relative;
    border: 3px dashed #ccc;
    min-height: 80px;
    padding: 15px;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: $gold;
      opacity: .05;
      transition: opacity $trans;
    }
    &:hover{
      &::after{
        background: $gold;
        opacity: .1;
      }

    }
    p{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 14px;
      span{
        opacity: .5;
      }
    }
    input{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
      z-index: 2;
      cursor: pointer;
    }
  }
}

</style>

<template>
  <div class="header-menu">
    <transition name="fade" mode="out-in">
      <div class="header-menu__bg"
           v-show="isOpenMenu"
           @click="closeMenu"></div>
    </transition>
    <div class="header-menu__wrap">
      <Burger/>
      <nav class="header-nav" :class="{ 'active' : isOpenMenu }">
        <ul class="header-nav__list">
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'home' })">
              {{ $t('navigateHome') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'quick-rules' })">
              {{ $t('quickRules') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'fairplay-position' })">
              {{ $t('fairPlayPositionTitle') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'license-deadlines' })">
              {{ $t('navigationLicenseDeadlines') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'faq' })">
              {{ $t('faq') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'terms-of-use' })">
              {{ $t('termsOfUse') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'privacy-policy' })">
              {{ $t('privacyPolicy') }}
            </router-link>
          </li>
          <li class="header-nav__item">
            <router-link :to="$localize({ name: 'create-license' })">
              {{ $t('createLicense') }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import Burger from '@/components/atoms/Burger.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'HeaderMenu',
  components: {
    Burger
  },
  watch: {
    '$route.path' () {
      this.closeMenu()
    }
  },
  computed: {
    ...mapGetters({
      isOpenMenu: 'system/isOpenMenu'
    })
  },
  methods: {
    ...mapMutations({
      changeShowMenu: 'system/changeShowMenu'
    }),
    closeMenu () {
      this.changeShowMenu(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-menu {
  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    background: rgba(0, 0, 0, 0.7);
  }
  &__wrap {
    position: relative;
    z-index: 4;
  }
  .header-nav {
    position: fixed;
    z-index: 5;
    right: 0;
    top: 0;
    background: white;
    transform: translateX(100%);
    transition: transform $trans;
    &.active {
      transform: translateX(0);
    }
  }
}
.header-nav{
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: $gold;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darkgoldenrod;
  }
  &__list{
    padding: 50px 0;
    max-width: 400px;
    @include respondTo(600px){
      max-width: 250px;
    }
  }
  &__item{
    a{
      display: block;
      padding: 20px 80px;
      background-color: $white;
      transition: background-color $trans, color $trans;
      text-decoration: none;
      color: $black;
      font-size: 16px;
      line-height: 1.1;
      @include respondTo(800px){
        font-size: 14px;
        padding: 15px 30px;
      }
      &.router-link-exact-active{
        background-color: $gold;
        color: $white;
      }
      &:hover{
        background-color: $gold;
        color: $white;
      }
    }
  }
}
</style>

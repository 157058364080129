import en from './en'
import es from './es'
import ru from './ru'
import zh from './zh'
import jp from './jp'
import pt from './pt'
import fr from './fr'
import de from './de'

export default { en, es, ru, zh, jp, pt, fr, de }

import store from '@/store'
import api from '@/api'
import axios from 'axios'
const camelcaseObjectDeep = require('camelcase-object-deep')
const snakecaseKeys = require('snakecase-keys')
const apiLeague = `${process.env.VUE_APP_LIVE_LEAGUE_API}`

const state = {
  isLoading: false,
  availableSlug: null,
  availablePhone: null,
  availableUsername: null
}

const getters = {
  isLoading: state => state.isLoading,
  availableSlug: state => state.availableSlug,
  availablePhone: state => state.availablePhone,
  availableUsername: state => state.availableUsername
}

const actions = {
  createLicense: async ({ commit }, payload) => {
    commit('changeLoading', true)
    const formData = new FormData()
    Object.entries(snakecaseKeys(payload)).forEach(e => {
      const [key, value] = e
      formData.set(key, value)
    })

    try {
      const response = await axios({
        method: 'post',
        url: `${apiLeague}/season-licences/?pre_creation=true`,
        data: formData,
        headers: {
          'Hola-Token': store.getters['hola/tokens'].accessToken
        }
      })

      return camelcaseObjectDeep(response.data)
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('changeLoading', false
      )
    }
  },
  checkLicenseSlug: async ({ commit }, payload) => {
    try {
      commit('changeLoading', true)
      const response = await api.license.checkLicenseSlug(payload)
      commit('setLicenseSlug', response)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLoading', false)
    }
  },
  checkUsername: async ({ commit }, payload) => {
    try {
      commit('changeLoading', true)
      const response = await api.license.checkUsername(payload)
      commit('setUsername', response)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLoading', false)
    }
  },
  checkUserPhone: async ({ commit }, { phone }) => {
    try {
      commit('changeLoading', true)
      const response = await api.license.checkUserPhone(phone)
      commit('setUserPhone', response)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeLoading', false)
    }
  }
}

const mutations = {
  changeLoading (state, status) {
    state.isLoading = status
  },
  setLicenseSlug (state, data) {
    state.availableSlug = data
  },
  setUsername (state, data) {
    state.availableUsername = data
  },
  setUserPhone (state, data) {
    state.availablePhone = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}

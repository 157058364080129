<template>
  <div class="auth-hola">
    <v-card-subtitle v-show="!showOtp" class="text-center mt-2">
      {{ $t('pleaseEnterPhone') }}
    </v-card-subtitle>
    <v-form class="form pa-3 pb-5">
      <v-row v-show="!showOtp">
        <v-col cols="12" class="pa-0">
          <div class="form-field">
            <div class="form-field__body">
              <vue-tel-input-vuetify
                :rules="defaultRules"
                v-model="payload.phone"
                :placeholder="$t('fieldPhoneLabel')"
                :label="$t('fieldPhoneLabel')"
                :inputOptions="{
                  showDialCode: true,
                }"
                :error-messages="availablePhone && availablePhone.result"
                :valid-characters-only="true"
                @input="onInput"
              />
            </div>
          </div>
        </v-col>
        <div class="mt-3" style="display: flex;justify-content: center; width: 100%;">
          <v-btn color="primary"
                 large
                 :loading="isLoading || availablePhoneLoading"
                 :disabled="!isValidPhone"
                 @click.prevent="submitPhoneForm()">
            {{ $t('loginOrRegister') }}
          </v-btn>
        </div>
      </v-row>
      <v-row v-if="showOtp" class="mt-3">
        <v-col cols="12" class="pa-0">
          <div class="form-field">
            <div class="form-field__label">
              {{ $t('fieldOtpLabel') }}
            </div>
            <div class="form-field__body">
              <v-text-field
                v-model.trim="payload.otp"
                :error-messages="serverErrors.otp"
                class="mt-0 pt-0 text-center"
                prepend-inner-icon="mdi-pencil"
                :rules="onlyNumberRules"
                name="otp"
                type="number"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="pa-0" v-if="otpResponse && otpResponse.isPinProtected">
          <div class="form-field">
            <div class="form-field__label">
              {{ $t('fieldPinCodeLabel') }}
            </div>
            <div class="form-field__body">
              <v-text-field
                v-model.trim="payload.pinCode"
                :error-messages="serverErrors.pin"
                class="mt-0 pt-0 text-center"
                prepend-inner-icon="mdi-pencil"
                :rules="onlyNumberRules"
                name="otp"
                type="number"
              />
            </div>
          </div>
        </v-col>
        <div style="display: flex;justify-content: center; width: 100%;" class="mb-3">
          <div class="auth-form__resend" style="text-align: center">
            <p>
              {{ $t('otpDontReceiveCode') }}
            </p>
            <v-btn color="transparent"
                   :disabled="showTimeLabel"
                   class="elevation-0 mt-2 mb-2"
                   small
                   @click.prevent="resendOtp">
              {{ $t('otpRequestSMSAgain') }}
            </v-btn>
            <p class="auth-sidebar__timer" v-show="showTimeLabel">
              {{ $t('otpRequestNewCode') }}:
              <span>{{ currentTime }}</span>
            </p>
          </div>
        </div>
        <div class="mt-3" style="display: flex;justify-content: center; width: 100%;">
          <v-btn color="primary"
                 large
                 :loading="isLoading"
                 :disabled="otpResponse && otpResponse.isPinProtected ? payload.otp.length !== 6 && payload.pinCode.length !== 6  : payload.otp.length !== 6"
                 @click.prevent="submitOtpForm()">
              {{ $t('confirm') }}
          </v-btn>
          </div>
        <div style="display: flex;justify-content: center; width: 100%;" class="mt-6">
          <v-btn color="transparent"
                 class="elevation-0"
                 small
                 @click.prevent="showOtp = false">
            {{ $t('editPhoneNumber') }}
          </v-btn>
        </div>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import validation from '@/mixins/validation'
import { mapActions, mapGetters } from 'vuex'

const COUNTDOWN_TIME = 30
let timerTicker = null

export default {
  name: 'AuthInHola',
  mixins: [validation],
  props: {
    isUniquePhone: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change-step', 'close-dialog'],
  data () {
    return {
      isValidPhone: false,
      showOtp: false,
      serverErrors: {},
      payload: {
        phone: '',
        pinCode: '',
        otp: '',
        device: {
          id: '73617948-6f6c-6120-426c-6f6720777000',
          type: 'web'
        }
      },
      showTimeLabel: false,
      currentTime: null
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'hola/isLoading',
      otpResponse: 'hola/otpResponse',
      authResponse: 'hola/authResponse',
      availablePhone: 'license/availablePhone',
      availablePhoneLoading: 'license/isLoading'
    })
  },
  mounted () {
    this.payload.phone = this.phone
  },
  methods: {
    ...mapActions({
      getOtp: 'hola/GET_OTP',
      authentication: 'hola/AUTHENTICATION',
      checkAvailablePhone: 'license/CHECK_AVAILABLE_PHONE'
    }),
    countdown () {
      let minutes = Math.floor(COUNTDOWN_TIME / 60)
      let seconds = COUNTDOWN_TIME % 60
      let currentVal = COUNTDOWN_TIME
      this.showTimeLabel = true
      timerTicker = setInterval(() => {
        currentVal--
        seconds = currentVal % 60
        minutes = Math.floor(currentVal / 60)
        this.currentTime = `${minutes < 10 ? `0${minutes}` : minutes}` + ':' + `${seconds < 10 ? `0${seconds}` : seconds}`
        if (this.currentTime === '00:01') {
          this.showTimeLabel = false
          clearInterval(timerTicker)
        }
      }, 1000)
      this.currentTime = `${minutes < 10 ? `0${minutes}` : minutes}` + ':' + `${seconds < 10 ? `0${seconds}` : seconds}`
    },

    onInput (formattedNumber, { number, valid }) {
      this.payload.phone = number.e164
      this.isValidPhone = valid
    },
    submitPhoneForm () {
      if (this.isUniquePhone) {
        if (this.isValidPhone) {
          this.checkAvailablePhone({ phone: this.payload.phone }).then(() => {
            if (!this.availablePhone) {
              this.showTimeLabel = true
              this.getOtp(this.payload).then(() => {
                this.showOtp = true
                this.countdown()
              })
            }
          })
        }
      } else {
        this.getOtp(this.payload).then(() => {
          this.showOtp = true
          this.countdown()
        })
      }
    },
    resendOtp () {
      this.countdown()
      this.getOtp(this.payload)
    },
    submitOtpForm () {
      this.serverErrors = {}
      setTimeout(() => {
        this.authentication(this.payload).then(() => {
          this.$emit('change-step')
          this.$emit('close-dialog', false)
        }).catch(error => {
          if (error.status === 401) {
            const { type } = error.data.data
            if (type === 'otp_unconfirmed') {
              this.$set(this.serverErrors, 'otp', this.$t(type))
            }
            if (type === 'pin_unconfirmed') {
              this.$set(this.serverErrors, 'pin', this.$t(type))
            }
          }
        })
      }, 100)
    }
  }
}
</script>
<style lang="scss">
.auth-sidebar__timer{
  span{
    margin-top: 5px;
    display: block;
    text-align: center;
  }
}
.auth-form__resend{
  text-align: center;
  button{
    width: 100%;
  }
}
</style>

import { render, staticRenderFns } from "./Opportunities.vue?vue&type=template&id=2d4d83f7&scoped=true&"
import script from "./Opportunities.vue?vue&type=script&lang=js&"
export * from "./Opportunities.vue?vue&type=script&lang=js&"
import style0 from "./Opportunities.vue?vue&type=style&index=0&id=2d4d83f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d4d83f7",
  null
  
)

export default component.exports
import api from '@/api'

const state = {
  sendContactLoading: false
}

const getters = {
  sendContactLoading: state => state.sendContactLoading
}

const actions = {
  async sendContactMessage ({ commit }, payload) {
    try {
      commit('changeContactLoading', true)
      await api.contact.sendContactMessage(payload)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeContactLoading', false)
    }
  }
}

const mutations = {
  changeContactLoading (state, status) {
    state.sendContactLoading = status
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

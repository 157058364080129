import axios from 'axios'
import applyConverters from 'axios-case-converter'
import qs from 'qs'

const instance = applyConverters(axios.create({
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
}))

instance.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.reject(error)
)
instance.interceptors.response.use(
  response => {
    return response.data
  },
  async error => {
    // if (error.response.status === 403) {
    //   const { errorType, data: { type } } = error.response.data
    //   if (errorType === 'access' && type === 'expired' && error.config.url !== REFRESH_TOKEN_URL) {
    //     if (!refreshTokenPromise) {
    //       refreshTokenPromise = store.dispatch('auth/refresh')
    //     }
    //     await refreshTokenPromise
    //     refreshTokenPromise = null
    //     return instance(error.config)
    //   }
    //   if (errorType === 'access' && type === 'auth') {
    //     return store.dispatch('auth/logoutLocal')
    //   }
    // }
    // if (error.response.status === 401) {
    //   await store.dispatch('auth/logoutLocal')
    // }
    return Promise.reject(error)
  }
)

export default instance

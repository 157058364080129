import Accordion from './components/Accordion.vue'
export default {
  name: 'FAQ',
  data () {
    return {
      faqList: [
        {
          id: 0,
          question: this.$t('faqListSlogan1'),
          answer: this.$t('faqListSlogan2')
        },
        {
          id: 1,
          question: this.$t('faqListSlogan3'),
          answer: this.$t('faqListSlogan4')
        },
        {
          id: 2,
          question: this.$t('faqListSlogan5'),
          answer: this.$t('faqListSlogan6')
        },
        {
          id: 3,
          question: this.$t('faqListSlogan7'),
          answer: this.$t('faqListSlogan8')
        },
        {
          id: 4,
          question: this.$t('faqListSlogan9'),
          answer: this.$t('faqListSlogan10')
        },
        {
          id: 5,
          question: this.$t('faqListSlogan11'),
          answer: this.$t('faqListSlogan12')
        },
        {
          id: 6,
          question: this.$t('faqListSlogan13'),
          answer: this.$t('faqListSlogan14')
        },
        {
          id: 7,
          question: this.$t('faqListSlogan15'),
          answer: this.$t('faqListSlogan16')
        },
        {
          id: 8,
          question: this.$t('faqListSlogan17'),
          answer: this.$t('faqListSlogan18')
        },
        {
          id: 9,
          question: this.$t('faqListSlogan19'),
          answer: this.$t('faqListSlogan20')
        },
        {
          id: 10,
          question: this.$t('faqListSlogan21'),
          answer: this.$t('faqListSlogan22')
        },
        {
          id: 11,
          question: this.$t('faqListSlogan23'),
          answer: this.$t('faqListSlogan24')
        },
        {
          id: 12,
          question: this.$t('faqListSlogan25'),
          answerList: {
            itemList: [
              {
                id: 0,
                item: this.$t('faqListSlogan26')
              },
              {
                id: 1,
                item: this.$t('faqListSlogan27')
              },
              {
                id: 2,
                item: this.$t('faqListSlogan28')
              }
            ],
            dateLists: [
              [
                this.$t('faqListSlogan29'),
                this.$t('faqListSlogan30'),
                this.$t('faqListSlogan31'),
                this.$t('faqListSlogan32'),
                this.$t('faqListSlogan33'),
                this.$t('faqListSlogan34')
              ],
              [
                this.$t('faqListSlogan35'),
                this.$t('faqListSlogan36'),
                this.$t('faqListSlogan37'),
                this.$t('faqListSlogan38'),
                this.$t('faqListSlogan39'),
                this.$t('faqListSlogan40')
              ],
              [
                this.$t('faqListSlogan41'),
                this.$t('faqListSlogan42'),
                this.$t('faqListSlogan43'),
                this.$t('faqListSlogan44'),
                this.$t('faqListSlogan45'),
                this.$t('faqListSlogan46')
              ],
              [
                this.$t('faqListSlogan47'),
                this.$t('faqListSlogan48'),
                this.$t('faqListSlogan49'),
                this.$t('faqListSlogan50'),
                this.$t('faqListSlogan51'),
                this.$t('faqListSlogan52')
              ],
              [
                this.$t('faqListSlogan53'),
                this.$t('faqListSlogan54'),
                this.$t('faqListSlogan55'),
                this.$t('faqListSlogan56'),
                this.$t('faqListSlogan57'),
                this.$t('faqListSlogan58')
              ],
              [
                this.$t('faqListSlogan59'),
                this.$t('faqListSlogan60'),
                this.$t('faqListSlogan61'),
                this.$t('faqListSlogan62'),
                this.$t('faqListSlogan63'),
                this.$t('faqListSlogan64')
              ],
              [
                this.$t('faqListSlogan65'),
                this.$t('faqListSlogan66'),
                this.$t('faqListSlogan67'),
                this.$t('faqListSlogan68'),
                this.$t('faqListSlogan69'),
                this.$t('faqListSlogan70')
              ],
              [
                this.$t('faqListSlogan71'),
                this.$t('faqListSlogan72'),
                this.$t('faqListSlogan73'),
                this.$t('faqListSlogan74'),
                this.$t('faqListSlogan75'),
                this.$t('faqListSlogan76')
              ],
              [
                this.$t('faqListSlogan77'),
                this.$t('faqListSlogan78'),
                this.$t('faqListSlogan79'),
                this.$t('faqListSlogan80'),
                this.$t('faqListSlogan81'),
                this.$t('faqListSlogan82')
              ],
              [
                this.$t('faqListSlogan83'),
                this.$t('faqListSlogan84'),
                this.$t('faqListSlogan85'),
                this.$t('faqListSlogan86'),
                this.$t('faqListSlogan87'),
                this.$t('faqListSlogan88')
              ]
            ]
          }
        },
        {
          id: 13,
          question: this.$t('faqListSlogan89'),
          answer: this.$t('faqListSlogan90')
        }
      ]
    }
  },
  components: {
    Accordion
  },
  head: {
    title: function () {
      return {
        inner: 'xtremo 7',
        separator: '-',
        complement: this.$t('faqPageTitle')
      }
    }
  }
}

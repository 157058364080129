<template>
  <div class="burger">
    <div class="burger-wrap">
      <input id="menu__toggle" type="checkbox" @click="toggleShowMenu" :class="{ 'active': isOpenMenu }" />
      <label class="menu__btn" for="menu__toggle">
        <span></span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Burger',
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      isOpenMenu: 'system/isOpenMenu'
    })
  },
  methods: {
    ...mapMutations({
      changeShowMenu: 'system/changeShowMenu'
    }),
    toggleShowMenu () {
      if (this.isOpenMenu) {
        this.changeShowMenu(false)
      } else {
        this.changeShowMenu(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.burger{
  &-wrap{
    width: 26px;
    height: 26px;
    position: relative;
  }
  input {
    display: none;
  }
  #menu__toggle {
    opacity: 0;
  }
  #menu__toggle.active ~ .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle.active ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  #menu__toggle.active ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle.active ~ .menu__box {
    visibility: visible;
    left: 0;
  }
  .menu__btn {
    display: flex;
    align-items: center;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $gold;
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
}
</style>

<template>
  <v-app id="app" class="app">
    <div class="create-license">
    <div class="create-license__wrap">
      <h1 class="main-title text-center">
        {{ $t('createLicense') }}
      </h1>
      <div class="main-form anchour-mobile mt-4 mb-10">
        <div class="l-container">
          <div class="main-form__wrap">
            <div class="main-form__right pt-1">
              <v-stepper
                v-model="step"
                alt-labels
                transition="none"
                class="elevation-0"
                color="lightBackground">
                <v-stepper-header transition="none" class="elevation-0 mb-7">
                  <v-stepper-step
                    color="secondary"
                    class="pb-0"
                    :complete="step > 1"
                    step="1"
                    :rules="[() => !stepsValidation.user.length]"
                  >
                    {{ $t('user') }}
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    class="pb-0"
                    style="white-space: nowrap"
                    color="secondary"
                    :complete="step > 2"
                    step="2"
                  >
                    <span class="pl-1 pr-1 d-block" style="max-width: 120px; white-space: normal; text-align: center;">
                      {{ $t('stepHola') }}
                    </span>
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    class="pb-0"
                    style="white-space: nowrap"
                    color="secondary"
                    :complete="step > 3"
                    step="3">
                    <span class="pl-1 pr-1 d-block" style="max-width: 120px; white-space: normal; text-align: center;">
                      {{ $t('stepSocial') }}
                    </span>
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    class="pb-0"
                    color="secondary"
                    :complete="step > 4"
                    :rules="[() => !stepsValidation.agreement.length]"
                    step="4">
                    {{ $t('agreement') }}
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items color="lightBackground" class="pt-4 pb-4">
                  <v-stepper-content step="1" color="lightBackground" class="pa-0">
                    <user
                      :server-errors="stepsValidation.user"
                      @update="updateFormUser($event)">
                    </user>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="pa-0">
                    <hola-profile-form
                      :shared-user-data="sharedCommonData"
                      @handle-form="updateHolaProfile($event)"
                      @change-step="step = $event"
                      v-if="step === 2">
                    </hola-profile-form>
                  </v-stepper-content>
                  <v-stepper-content step="3" class="pa-0">
                    <social-profile-form
                      v-if="step === 3"
                      :shared-user-data="sharedCommonData"
                      :playerLoading="isLoading"
                      @change-step="step = $event"
                      @handle-form="step++"
                      @update-nickname="payload.socialSpace = $event">
                    </social-profile-form>
                  </v-stepper-content>
                  <v-stepper-content step="4" class="agreement-block pa-0">
                    <agreement
                      :is-loading="isLoading"
                      :server-errors="stepsValidation.agreement"
                      @update="createLicense($event)"
                      @change-step="step--">
                    </agreement>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-if="showPopupAuth"
      :retain-focus="false"
      style="overflow-x: hidden;"
      v-model="showPopupAuth"
      :max-width="400"
      transition="component-fade"
    >
      <v-card tile class="popup-content">
        <v-toolbar
          style="flex: 0 1 auto;"
          height="60px"
          flat
          class="popup-toolbar"
          color="secondary"
          dark
        >
          <v-toolbar-title>
            {{ $t('loginOrRegisterUsingHola') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="showPopupAuth = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <auth-in-hola
            @change-step="step++"
            @close-dialog="showPopupAuth = $event">
          </auth-in-hola>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  </v-app>
</template>
<script>
import Agreement from './_Agreement'
import User from './_User'
import AuthInHola from '@/components/features/AuthInHola'
import HolaProfileForm from '@/components/features/HolaProfileForm'
import SocialProfileForm from '@/components/features/SocialProfileForm'

import { mapActions, mapGetters } from 'vuex'
import { clone } from 'lodash'
import { createLicenseStepsFields } from '@/helpers'

import validation from '@/mixins/validation'

export default {
  name: 'CreateLicenseExternalView',
  mixins: [validation],
  components: {
    Agreement,
    User,
    AuthInHola,
    HolaProfileForm,
    SocialProfileForm
  },
  head: {
    title: function () {
      return {
        inner: 'xtremo 7',
        separator: '-',
        complement: this.$t('createLicense')
      }
    }
  },
  data () {
    return {
      showPopupAuth: false,
      clientIp: '',
      step: 1,
      payload: {
        socialSpace: '',
        adminPhone: '',
        adminEmail: '',
        typeData: 'formData'
      },
      stepsValidation: {
        user: [],
        agreement: []
      },
      sharedCommonData: {}
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'license/isLoading'
    })
  },
  watch: {
    step () {
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
      })
    }
  },
  created () {
    fetch('https://api.ipify.org?format=json')
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        this.clientIp = data.ip
      })
  },
  methods: {
    ...mapActions({
      actionCreateLicense: 'license/createLicense'
    }),
    updateFormUser (data) {
      Object.assign(this.payload, data)
      this.showPopupAuth = true

      this.sharedCommonData = {
        firstName: this.payload.adminFirstName,
        lastName: this.payload.adminLastName
      }
    },
    updateHolaProfile (data) {
      const { email, phone, holaId } = data
      this.payload.adminEmail = email
      this.payload.adminHolaId = holaId
      this.payload.adminPhone = phone

      this.sharedCommonData = {
        ...data
      }

      this.step++
    },
    updatePayloadAndStep (data) {
      Object.assign(this.payload, data)
      this.step++
    },
    createLicense (data = {}) {
      Object.assign(this.payload, data)
      const requestPayload = clone(this.payload)
      requestPayload.socialSpace = `${process.env.VUE_APP_SOCIAL_LINK}${this.payload.socialSpace}`
      requestPayload.parent = process.env.VUE_APP_X7_ID
      requestPayload.isApproved = false
      requestPayload.adminIp = this.clientIp

      this.actionCreateLicense(requestPayload).then(async () => {
        this.$toasted.success(this.$t('successCreatedLicense'))
        await this.$router.push({ name: 'home' })
      }).catch(error => {
        if (error.response.status === 400) {
          this.validationSteps(error.response.data)
        }
      })
    },
    resetValidationSteps () {
      Object.keys(this.stepsValidation).forEach(key => {
        this.stepsValidation[key] = []
      })
    },
    validationSteps (errors) {
      this.resetValidationSteps()

      Object.entries(errors).forEach(([key, value]) => {
        const errorItem = {
          key: key,
          value: value[0]
        }

        Object.keys(this.stepsValidation).forEach(subKey => {
          if (createLicenseStepsFields[subKey].fields.includes(key)) {
            this.stepsValidation[subKey].push(errorItem)
          }
        })
      })
    }
  }
}
</script>
<style lang="scss">

.license-errors{
  &__item{
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    color: red;
    margin-bottom: 4px;
    i{
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
.create-license {
  display: flex;
  justify-content: center;
  .v-stepper__wrapper {
    overflow: visible;
  }

  &__wrap {
    width: 100%;
  }
}
.hola-profile__item{
  background: #efefef;
  padding: 20px 15px 1px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.hola-profile__phone-val{
  color: #999;
  margin-top: 10px;
}
.social-profile{
  .buttons{
    justify-content: flex-end!important;
  }
  &__item{
    background: #efefef;
    padding: 20px 15px 1px;
    margin-bottom: 15px;
    border-radius: 4px;
  }
  &__wrap{
    &.disabled{
      opacity: .6;
      pointer-events: none;
    }
  }
}
.l-container{
  max-width: 1185px;
  margin: 0 auto;
  padding: 0 12px;
}
</style>

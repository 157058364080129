import axios from 'axios'
import { base64ToFile } from '@/helpers'
const apiAuth = process.env.VUE_APP_HOLA_API
const apiMediaService = process.env.VUE_APP_MEDIA_SERVICE_API

const camelcaseObjectDeep = require('camelcase-object-deep')
const snakecaseKeys = require('snakecase-keys')

const state = {
  authResponse: null,
  otpResponse: null,
  profile: null,
  packages: null,
  isLoading: false,
  tokens: null,
  socialProfiles: null
}

const getters = {
  authResponse: state => state.authResponse,
  isLoading: state => state.isLoading,
  otpResponse: state => state.otpResponse,
  profile: state => state.profile,
  packages: state => state.packages,
  tokens: state => state.tokens,
  socialProfiles: state => state.socialProfiles
}

const actions = {
  AUTHENTICATION: async ({ commit }, payload) => { // create user or login
    try {
      commit('CHANGE_LOADING', true)
      const response = await axios.post(`${apiAuth}/auth/user/auth`, snakecaseKeys(payload))
      commit('SET_AUTH_RESPONSE', camelcaseObjectDeep(response.data.data))
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  GET_OTP: async ({ commit }, payload) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios.post(`${apiAuth}/auth/otp`, snakecaseKeys(payload))
      commit('SET_OTP_RESPONSE', camelcaseObjectDeep(response.data.data))
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  GET_PROFILE: async ({ commit }) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios({
        method: 'get',
        url: `${apiAuth}/user/user/my_profile`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      commit('SET_PROFILE', camelcaseObjectDeep(response.data.data))
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  UPDATE_PROFILE: async ({ commit }, payload) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios({
        method: 'PATCH',
        url: `${apiAuth}/user/user/my_profile`,
        data: snakecaseKeys(payload),
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      commit('SET_PROFILE', camelcaseObjectDeep(response.data.data))
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  UPDATE_IMAGE: async ({ commit }, payload) => {
    const formData = new FormData()
    formData.set('file', base64ToFile(payload.file, 'avatar'))
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios({
        method: 'post',
        url: `${apiMediaService}/upload`,
        data: formData,
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      return camelcaseObjectDeep(response.data.data) // return imgLink
    } catch (e) {
      throw e
    }
  },
  CREATE_SOCIAL: async ({ commit }, payload) => {
    commit('CHANGE_LOADING', true)
    const formData = new FormData()
    Object.entries(snakecaseKeys(payload)).forEach(e => {
      const [key, value] = e
      if (key === 'picture' && value.length > 100) {
        formData.set('picture', base64ToFile(payload.picture, key))
      } else {
        formData.set(key, value)
      }
    })

    try {
      const response = await axios({
        method: 'post',
        url: `${apiAuth}/social/profile/my/`,
        data: formData,
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })

      return camelcaseObjectDeep(response.data)
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('CHANGE_LOADING', false
      )
    }
  },
  GET_SOCIAL_PROFILES: async ({ commit }) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios({
        method: 'get',
        url: `${apiAuth}/social/profile/my/?my=true&limit=200`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      commit('SET_SOCIAL_PROFILES', camelcaseObjectDeep(response.data))
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  CREATE_STREAM: async ({ dispatch, commit }, payload) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios({
        method: 'POST',
        url: `${apiAuth}/stream/broadcasts/stream/create`,
        data: snakecaseKeys(payload),
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      return response.data.data
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  UPDATE_STREAM: async ({ dispatch, commit }, { id, ...others }) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios({
        method: 'PUT',
        url: `${apiAuth}/stream/broadcasts/stream/${id}`,
        data: snakecaseKeys(others),
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      return response.data.data
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  GET_HOLA_PACKAGES: async ({ commit }) => {
    try {
      commit('CHANGE_LOADING', true)

      const response = await axios({
        method: 'GET',
        url: `${apiAuth}/payment/user/packages`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessHolaToken')}`
        }
      })
      commit('SET_HOLA_PACKAGES', camelcaseObjectDeep(response.data.data))
    } catch (e) {
      throw camelcaseObjectDeep(e.response)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  }
}

const mutations = {
  SET_SOCIAL_PROFILES (state, data) {
    state.socialProfiles = data
  },
  SET_PROFILE (state, data) {
    state.profile = data
  },
  SET_OTP_RESPONSE (state, data) {
    state.otpResponse = data
  },
  SET_HOLA_PACKAGES (state, data) {
    state.packages = data
  },
  SET_AUTH_RESPONSE (state, data) {
    state.authResponse = data
    localStorage.setItem('accessHolaToken', data.token.accessToken)
    state.tokens = data.token
  },
  CHANGE_LOADING (state, status) {
    state.isLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="about">
    <div class="about-wrap">
      <div class="container">
        <div class="about-title">
          {{ $t('aboutTitle') }}
        </div>
        <div class="about-list">
          <div class="about-item">
            {{ $t('aboutItem1') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem2') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem3') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem4') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem5') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem6') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem7') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem8') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem9') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem10') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem11') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem12') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem13') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem14') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem15') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem16') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem17') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem18') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem19') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem20') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem21') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem22') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem23') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem24') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem25') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem26') }}
          </div>
          <div class="about-item">
            {{ $t('aboutItem27') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>
.about {
  padding: 120px 0;
  background: url('../../../assets/img/home/about-new.jpg') center center no-repeat;
  background-size: cover;
  position: relative;
  @include respondTo(800px){
    padding: 30px 0;
  }
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
  }
  &-wrap{
    position: relative;
    z-index: 1;
  }
  &-title{
    font-size: 36px;
    line-height: 1.1;
    color: $white;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: center;
    @include respondTo(600px){
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  &-item{
    padding: 7px 0;
    font-size: 16px;
    line-height: 1.2;
    color: $white;
    font-weight: 500;
    text-align: center;
    @include respondTo(600px){
      font-size: 14px;
      padding: 5px 0;
      font-weight: normal;
    }
  }
}
</style>

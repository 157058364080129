<template>
  <label class="form--file">
    <slot />
    <p v-if="displayLabel"
          class="form--file-label">
      <span>
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path
          d="M16.5,6V17.5C16.5,19.71 14.71,21.5 12.5,21.5C10.29,21.5 8.5,19.71 8.5,17.5V5C8.5,3.62 9.62,2.5 11,2.5C12.38,2.5 13.5,3.62 13.5,5V15.5C13.5,16.05 13.05,16.5 12.5,16.5C11.95,16.5 11.5,16.05 11.5,15.5V6H10V15.5C10,16.88 11.12,18 12.5,18C13.88,18 15,16.88 15,15.5V5C15,2.79 13.21,1 11,1C8.79,1 7,2.79 7,5V17.5C7,20.54 9.46,23 12.5,23C15.54,23 18,20.54 18,17.5V6H16.5Z"></path></svg>
      </span>
      <span class="form--file__name">{{ displayLabel }}</span>
    </p>
    <input
      :accept="accept"
      :multiple="multiple"
      type="file"
      class="form--file-input"
      @change="handleFileChange"
    >
  </label>
</template>

<script>
export default {
  name: 'file-input',
  props: {
    value: [File, FileList],
    label: String,
    accept: String,
    multiple: Boolean,
    withName: Boolean
  },
  computed: {
    fileName () {
      if (this.withName && this.value) {
        return this.value.name
      }
      return null
    },
    displayLabel () {
      return this.fileName ? this.fileName : this.label
    }
  },
  methods: {
    handleFileChange (e) {
      const files = e.target.files
      const value = this.multiple ? files : files[0]
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.form--file {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  position: relative;
  .remove-file{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
    z-index: 3;
  }
  p{
    display: flex;
  }
  span {
    display: block;
  }
  &-label {
    font-weight: 600;
    color: $white;
    transition: color $trans;
    &:hover {
      color: $gold;
      svg path {
        transition: fill $trans;
        fill: $gold;
      }
    }
  }
  &-input {
    display: none;
  }
  &__name{
    @include truncate();
  }
  svg path {
    transition: fill $trans;
    fill: $white;
  }
}
</style>

// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify)
Vue.use(DatetimePicker)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#d3b650',
        secondary: '#d3b650',
        third: '#88A7AD',
        lightBackground: '#FFFFFF',
        darkBackground: '#082C23',
        lightTextColor: '#FFFFFF',
        darkTextColor: '#161817',

        black: '#000',
        blue: '#404040',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'blue-grey': '#607D8B'
      },
      dark: {
        primary: '#444',
        'blue-grey': '#000'
      }
    }
  }
})
export default vuetify

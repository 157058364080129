<template>
  <div class="opportunities">
    <div class="opportunities-wrap">
      <div class="container">
        <div class="opportunities-title">
          {{ $t('opportunitiesTitle') }}
        </div>
        <div class="opportunities-desc">
         {{ $t('opportunitiesInfo') }}
        </div>
        <div class="opportunities-list">
          <div class="opportunities-item">
            <div class="opportunities-item__title">
              {{ $t('opportunitiesTitle1') }}
            </div>
            <div class="opportunities-item__info">
              {{ $t('opportunitiesDesc1') }}
            </div>
          </div>
          <div class="opportunities-item">
            <div class="opportunities-item__title">
              {{ $t('opportunitiesTitle2') }}
            </div>
            <div class="opportunities-item__info">
              {{ $t('opportunitiesDesc2') }}
            </div>
          </div>
          <div class="opportunities-item">
            <div class="opportunities-item__title">
              {{ $t('opportunitiesTitle3') }}
            </div>
            <div class="opportunities-item__info">
              {{ $t('opportunitiesDesc3') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Opportunities'
}
</script>

<style lang="scss" scoped>
.opportunities{
  padding: 120px 0;
  background: url('../../../assets/img/home/opportunities.jpg') center bottom no-repeat;
  background-size: cover;
  position: relative;
  @include respondTo(800px){
    padding: 30px 0;
  }
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.7);
  }
  &-wrap{
    position: relative;
    z-index: 1;
  }
  &-title{
    font-size: 36px;
    line-height: 1.2;
    color: $white;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    @include respondTo(600px){
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  &-desc{
    font-size: 18px;
    line-height: 1.5;
    color: $white;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
    }
  }
  &-item {
    margin-top: 30px;
    &__title {
      font-size: 22px;
      color: $green;
      margin-bottom: 15px;
      text-align: center;
      font-weight: 500;
      @include respondTo(600px){
        font-size: 16px;
      }
    }
    &__info{
      font-size: 18px;
      line-height: 1.5;
      color: $white;
      @include respondTo(600px){
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
      }
    }
  }
}
</style>

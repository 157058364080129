import HomeSlider from './components/HomeSlider.vue'
import Trademark from './components/Trademark.vue'
import Opportunities from './components/Opportunities.vue'
import About from './components/About.vue'
import Groups from './components/Groups.vue'
import ContactForm from './components/ContactForm.vue'

export default {
  name: 'home',
  components: {
    HomeSlider,
    Trademark,
    Opportunities,
    About,
    Groups,
    ContactForm
  },
  head: {
    title: function () {
      return {
        inner: 'xtremo 7 ',
        separator: ' ',
        complement: ''
      }
    }
  }
}

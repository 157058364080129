<template>
  <div class="groups">
    <div class="groups-wrap">
      <div class="container">
        <div class="groups-title">
          {{ $t('groupsInfo') }}
        </div>
      </div>
      <div class="groups-slider">
        <swiper
          class="groups-slider__wrap"
          ref="slider-items"
          :options="swiperOptions">
          <swiper-slide class="group-item">
            <a :href="togetherLink"
               target="_blank">
              <img src="../../../assets/img/group-slider/group-slide-1.jpg" alt="">
            </a>
          </swiper-slide>
          <swiper-slide class="group-item">
            <a :href="holaDashboardLink"
               target="_blank">
              <img src="../../../assets/img/group-slider/group-slide-2.jpg" alt="">
            </a>
          </swiper-slide>
          <swiper-slide class="group-item">
            <a href="">
              <img src="../../../assets/img/group-slider/group-slide-3.jpg" alt="">
            </a>
          </swiper-slide>
          <swiper-slide class="group-item">
            <a :href="liveLeagueLink"
               target="_blank">
              <img src="../../../assets/img/group-slider/group-slide-4.jpg" alt="">
            </a>
          </swiper-slide>
          <swiper-slide class="group-item">
            <a :href="moodLink"
               target="_blank">
              <img src="../../../assets/img/group-slider/group-slide-5.jpg" alt="">
            </a>
          </swiper-slide>
          <div class="swiper-button-prev swiper-prev" slot="button-prev">
            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512">
              <circle class="cls-1" cx="256" cy="256" r="234.67"/>
              <path class="cls-2"
                    d="M256,0C114.84,0,0,114.84,0,256S114.84,512,256,512,512,397.16,512,256,397.16,0,256,0Zm0,469.33c-117.63,0-213.33-95.7-213.33-213.33S138.37,42.67,256,42.67,469.33,138.37,469.33,256,373.63,469.33,256,469.33Z"/>
              <path class="cls-2"
                    d="M384,234.67H222.17l27.58-27.58a21.33,21.33,0,0,0-30.17-30.17l-64,64a21.33,21.33,0,0,0,0,30.17l64,64a21.33,21.33,0,1,0,30.17-30.17l-27.58-27.58H384a21.33,21.33,0,0,0,0-42.67Z"/>
            </svg>
          </div>
          <div class="swiper-button-next swiper-next" slot="button-next">
            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512">
              <circle class="cls-1" cx="256" cy="256" r="234.67"/>
              <path class="cls-2"
                    d="M256,0C114.84,0,0,114.84,0,256S114.84,512,256,512,512,397.16,512,256,397.16,0,256,0Zm0,469.33c-117.63,0-213.33-95.7-213.33-213.33S138.37,42.67,256,42.67,469.33,138.37,469.33,256,373.63,469.33,256,469.33Z"/>
              <path class="cls-2"
                    d="M292.42,176.92a21.33,21.33,0,0,0-30.17,30.17l27.58,27.58H128a21.33,21.33,0,0,0,0,42.67H289.83l-27.58,27.58a21.33,21.33,0,1,0,30.17,30.17l64-64a21.33,21.33,0,0,0,0-30.17Z"/>
            </svg>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swiper as SwiperClass, Pagination, Navigation, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import '@/assets/scss/swiper-bundle.scss'
import '@/assets/scss/effect-fade.scss'

SwiperClass.use([Pagination, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
export default {
  name: 'Groups',
  data () {
    return {
      swiperOptions: {
        centeredSlides: true,
        slidesPerView: 5,
        loop: true,
        speed: 500,
        breakpoints: {
          320: {
            slidesPerView: 2
          },
          480: {
            slidesPerView: 3
          },
          640: {
            slidesPerView: 4
          },
          800: {
            slidesPerView: 5
          }
        },
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false
        },
        navigation: {
          clickable: true,
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    liveLeagueLink () {
      return process.env.VUE_APP_LIVELEAGUE_LINK
    },
    holaDashboardLink () {
      return process.env.VUE_APP_HOLA_DASHBOARD_LINK
    },
    moodLink () {
      return process.env.VUE_APP_MOOD_LINK
    },
    togetherLink () {
      return process.env.VUE_APP_TOGETHER_LINK
    }
  }
}
</script>

<style lang="scss" scoped>
.groups {
  padding: 60px 0;
  @include respondTo(800px){
    padding: 20px 0;
  }
  &-title{
    max-width: 900px;
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    color: $black;
    font-weight: 500;
    @include respondTo(800px){
      font-size: 18px;
    }
  }
  &-slider{
    padding: 40px 0 10px;
    &__wrap{
      position: relative;
      padding-bottom: 60px;
    }
  }
  .group-item {
    opacity: 1 !important;
    cursor: pointer;
    a {
      text-decoration: none;
      display: block;
    }
    &:hover img{
      opacity: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      opacity: 0.7;
      transition: opacity $trans;
    }
  }
  .swiper-prev,
  .swiper-next {
    cursor: pointer;
    @include respondTo(600px){
      width: 35px;
      height: 35px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &:hover{
      svg path:last-child{
        animation: shake infinite 1s;
      }
      .cls-1{
        fill: $gold;
      }
    }
    svg {
      .cls-1 {
        fill: $white;
        transition: fill $trans;
      }
      .cls-2 {
        fill: $black;
        transition: fill $trans;
      }
      path:last-child{
        transition: transform $trans;
        transform-origin: center center;
      }
    }
  }
  .swiper-prev{
    position: absolute;
    left: 45%;
    bottom: 0;
    @include respondTo(1200px){
      left: 30%;
    }
  }
  .swiper-next{
    position: absolute;
    right: 45%;
    bottom: 0;
    @include respondTo(1200px){
      right: 30%;
    }
  }
}
</style>

<template>
  <div class="home-slider">
    <div class="container">
      <div class="home-slider__wrap">
        <img class="home-slider__bg" src="../../../assets/img/home-slider/slider-bg-text.png" alt="Slider">
        <swiper
          class="slider-wrap"
          ref="slider-items"
          :options="swiperOptions">
          <swiper-slide class="slider-item">
            <img src="../../../assets/img/home-slider/slide-1.png" alt="">
          </swiper-slide>
          <swiper-slide class="slider-item">
            <img src="../../../assets/img/home-slider/slide-2.png" alt="">
          </swiper-slide>
          <swiper-slide class="slider-item">
            <img src="../../../assets/img/home-slider/slide-3.png" alt="">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SwiperCore, { Swiper as SwiperClass, Pagination, Navigation, EffectFade, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import '@/assets/scss/swiper-bundle.scss'
import '@/assets/scss/effect-fade.scss'

SwiperCore.use([EffectFade])

SwiperClass.use([Pagination, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'HomeSlider',
  data () {
    return {
      currentSlide: 0,
      isLastSlide: false,
      swiperOptions: {
        effect: 'fade',
        loop: true,
        speed: 500,
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>

<style lang="scss" scoped>
.home-slider{
  &__wrap{
    position: relative;
    text-align: center;
    padding: 50px 0;
    @include respondTo(800px){
      padding: 20px 0;
    }
  }
  &__bg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    width: 100%;
    height: auto;
  }
  .slider-wrap{
    z-index: 2;
  }
  .slider-item{
    width: 100%;
    height: 600px;
    text-align: center;
    @include respondTo(800px){
      height: 300px;
    }
    img{
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>

export default {
  name: 'QuickRules',
  head: {
    title: function () {
      return {
        inner: 'xtremo 7',
        separator: '-',
        complement: this.$t('quickRulesPageTitle')
      }
    }
  }
}

import Vue from 'vue'

export default Vue.mixin({
  data () {
    return {
      defaultRules: [
        (v) => !!v || this.$t('validationRequired')
      ],
      emailRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => /.+@.+\..+/.test(v) || this.$t('validationEmail')
      ],
      required: [
        (v) => !!v || this.$t('validationRequired')
      ],
      onlyNumberRules: [
        (v) => !!v || this.$t('validationRequired'),
        v => /^[a-zA-Z-z0-9]+(\[a-zA-Z-z0-9])*$/.test(v) || 'Enter only numbers'
      ],
      websiteNameRules: [
        (v) => !!v || this.$t('validationRequired'),
        v => /^[\w\-\s]+$/.test(v) || 'Enter only latin letters'
      ],
      passwordRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => (v && v.length <= 50) || 'Password must be less than 50 characters',
        (v) => (v && v.length >= 8) || 'Password must be no less than 8 characters'
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => (v === this.payload.newPassword) || this.$t('validationSameAs')
      ]
    }
  }
})

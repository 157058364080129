<template>
  <div class="accordion" v-if="item">
    <div class="accordion-head" @click="toggleCardState" :class="{ 'active': expanded}">
      <div class="accordion-title">
        {{ item.question }}
      </div>
      <div class="accordion-icon">
        <div class="pluss">
          <div class="toggle" :class="{'open': !expanded}"></div>
        </div>
      </div>
    </div>

    <VueSlideToggle :open="expanded" tag="div" :duration="450">
      <div class="accordion-content" v-if="!item.answerList">
        {{ item.answer }}
      </div>
      <div class="accordion-content" v-else>
        <p v-for="(subItem,subIndex) in item.answerList.itemList"
           :key="subIndex + 350">
          {{ subItem.item }}
        </p>
        <ul v-for="(itemInList,itemsIndex) in item.answerList.dateLists"
            :key="itemsIndex"
            class="content-list">
          <li v-for="(elementText, elementIndex) in itemInList"
              :key="elementIndex + 100"
              class="content-list__item">
            {{ elementText }}
          </li>
        </ul>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle'

export default {
  name: 'Accordion',
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  methods: {
    toggleCardState () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion{
  &-head{
    cursor: pointer;
    position: relative;
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
    display: block;
    padding: 14px 20px;
    transition: background-color $trans, color $trans, border-color $trans;
    @include respondTo(600px){
      padding: 10px;
    }
  }

  &-icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    $w: 12px;
    $h: 3px;
    $bg: $white;
    $transition: 0.25s all cubic-bezier(0.17, 0.67, 0.09, 0.97);
    .wrap {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .toggle {
      width: $w;
      height: $h;
      background: $white;
      position: relative;
      transition: $transition;
      &.open::after {
        transform: rotate(90deg);
        opacity: 1;
      }
      &.open {
        transform: rotate(90deg);
        background: white;
      }
      &::after {
        content: "";
        width: $w;
        transition: $transition;
        transition-delay: 0.1s;
        height: $h;
        position: absolute;
        opacity: 0;
        background: $bg;
      }
    }
  }

  &-content{
    font-size: 14px;
    line-height: 20px;
    padding: 20px 35px;
    background-color: #fafafa;
    border: 1px solid #EEF0F3;
    @include respondTo(600px){
      font-size: 12px;
      line-height: 1.1;
    }
    p{
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 10px;
      @include respondTo(600px){
        font-size: 12px;
        line-height: 1.1;
      }
    }
    a{
      color: $black;
    }
    @include respondTo(600px){
      padding: 10px 15px;
    }
  }
  &-title{
    font-size: 14px;
    line-height: 18px;
    max-width: 95%;
    text-transform: uppercase;
    @include respondTo(600px){
      font-size: 12px;
      line-height: 16px;
      max-width: 92%;
    }
  }
  &-item{
    font-size: 14px;
    line-height: 22px;
  }
  .content-list{
    margin-bottom: 15px;
    list-style-type: circle;
    padding-left: 15px;
    @include respondTo(600px){
      padding-left: 5px;
      margin-bottom: 5px;
    }
    &__item {
      padding: 3px 0;
      font-size: 14px;
      line-height: 1.2;
      @include respondTo(600px){
        font-size: 12px;
      }
    }
  }
}
</style>

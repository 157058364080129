// utils
import './utils/translations'
import './utils/plugins'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
import router from './router'
import vuetify from '@/utils/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueTelInputVuetify, {
  vuetify
})

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
})

Vue.initI18nManager().then(() => {
  app.$mount('#app')
})

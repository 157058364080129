<template>
  <v-form class="form"
          v-model="validForm"
          @submit.prevent="submit"
          ref="form">
    <v-row class="mb-1 pt-4 social-profile__item pb-4">
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field mb-4">
          <div class="form-field__label">
            {{ $t('fieldFirstNameLabel') }}
          </div>
          <div class="form-field__body">
            <div class="form-field__body">
              <v-text-field
                v-model.trim="payload.adminFirstName"
                class="mt-0 pt-0"
                :rules="defaultRules"
                prepend-inner-icon="mdi-pencil"
                name="adminFirstName"
                type="text"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field mb-4">
          <div class="form-field__label">
            {{ $t('fieldLastNameLabel') }}
          </div>
          <div class="form-field__body">
            <div class="form-field__body">
              <v-text-field
                v-model.trim="payload.adminLastName"
                class="mt-0 pt-0"
                :rules="defaultRules"
                prepend-inner-icon="mdi-pencil"
                name="adminLastName"
                type="text"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="pt-0 pb-0">
        <div class="form-field mb-5">
          <div class="form-field__label">
            {{ $t('fieldUserNameLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.adminUsername"
              @input="handleInputUsername"
              class="mt-0 pt-0"
              :loading="isLoading"
              prepend-inner-icon="mdi-pencil"
              :rules="defaultRules"
              :error-messages="availableUsername && availableUsername.result"
              name="username"
              type="text"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldPasswordLabel') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model="payload.adminPassword"
              class="mt-0 pt-0"
              type="password"
              prepend-inner-icon="mdi-pencil"
              :rules="passwordRules"
              name="password"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pb-0">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('confirmPasswordField') }}
          </div>
          <v-text-field
            type="password"
            class="mt-0 pt-0"
            prepend-inner-icon="mdi-pencil"
            v-model="payload.newPassword"
            :rules="confirmPasswordRules"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <div class="main-form__right-btn d-flex justify-end mt-4">
      <v-btn
        :loading="isLoading"
        type="submit"
        class="elevation-0"
        color="primary">
        {{ $t('next') }}
        <v-icon right small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <div class="license-errors mt-3">
      <div class="license-errors__item" v-for="item in serverErrors" :key="item.key">
        <i>
          {{ $t(item.key) }}:
        </i>
        <p>
          {{ item.value }}
        </p>
      </div>
    </div>
  </v-form>
</template>
<script>
import validation from '@/mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'CreateLicenseUser',
  mixins: [validation],
  props: {
    serverErrors: {}
  },
  data () {
    return {
      validForm: false,
      payload: {
        adminUsername: '',
        adminPassword: '',
        newPassword: '',
        adminFirstName: '',
        adminLastName: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      availableUsername: 'license/availableUsername',
      isLoading: 'license/isLoading'
    })
  },
  methods: {
    ...mapActions({
      checkUsername: 'license/checkUsername'
    }),
    handleInputUsername: debounce(function () {
      if (!this.payload.adminUsername) return
      this.checkUsername({ username: this.payload.adminUsername })
    }, 500),
    async submit () {
      this.$refs.form.validate()
      if (this.validForm) {
        this.$emit('update', this.payload)
      }
    }
  }
}
</script>

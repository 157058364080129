<template>
  <v-form class="form social-profile"
          v-model="validForm"
          @submit.prevent="submit"
          ref="formSocial">
    <div class="social-profile__top" v-if="socialProfiles && socialProfiles.results.length">
      <div class="social-profile__image social-profile__item"
           style="padding-bottom: 10px;">
        <v-item-group mandatory>
          <h3>
            {{ $t('selectExistProfile') }}
          </h3>
          <v-container style="max-height: 380px; overflow-y: auto; overflow-x: hidden;">
            <v-row style="margin-right: -25px; margin-left: -25px;">
              <v-col
                v-for="profile in socialProfiles.results"
                :key="profile.nickname"
                cols="12"
                sm="3"
                md="2"
              >
                <v-item>
                  <v-card
                    :img="profile.picture"
                    class="d-flex align-center"
                    dark
                    height="100"
                    @click="setProfile(profile)"
                  >
                    <v-scroll-y-transition>
                      <div
                        v-show="activeProfile && activeProfile.nickname === profile.nickname"
                        style="background: rgba(171,154,6,.6); position: absolute; top: 0;right: 0;left: 0;bottom: 0;"
                        class="text-h5 flex-grow-1 text-center"
                      >
                        <v-icon large>mdi-check</v-icon>
                      </div>
                    </v-scroll-y-transition>
                    <p class="text-center full-width mb-0"
                       style="font-size: 14px; position: absolute; bottom: 0;left: 0;right: 0; background: rgba(0,0,0,.5); padding: 5px;">
                      /{{ profile.nickname }}
                    </p>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </div>
      <h3 class="text-center mb-5 mt-5"
          style="color: #999; font-weight: 400; font-size: 16px;">
        {{ $t('orCreateNewProfile') }}
      </h3>
    </div>
    <div class="social-profile__wrap" :class="{ 'disabled': !!activeProfile }">
      <div class="social-profile__image social-profile__item">
        <h3>
          {{ $t('fieldProfilePictureLabel') }}
        </h3>
        <div class="form-img">
          <image-file
            image-id="picture"
            :image="payload.picture"
            v-model="payload.picture">
          </image-file>
          <p class="form-img__subtext">
            {{ $t('socialProfileImageSubtext') }}
          </p>
        </div>
      </div>
      <div class="social-profile__image social-profile__item">
        <h3>
          {{ $t('profileSetting') }}
        </h3>
        <v-row class="mb-2 mt-2">
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldFirstNameLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="payload.firstName"
                  :error-messages="serverErrors.firstName"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  :rules="defaultRules"
                  name="name"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldLastNameLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="payload.lastName"
                  :error-messages="serverErrors.lastName"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  name="name"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldNicknameLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="payload.nickname"
                  :error-messages="serverErrors.nickname"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  :rules="websiteNameRules"
                  name="name"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldLocationCityCountryLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="payload.textLocation"
                  :error-messages="serverErrors.textLocation"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  :rules="defaultRules"
                  name="name"
                  type="text"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="d-flex"
         :class="$route.name !== 'create-license-with-auth' ? 'justify-space-between' : 'justify-end'">
      <v-btn
        v-if="$route.name !== 'create-license-with-auth'"
        class="elevation-0"
        @click.prevent="$emit('change-step', 2)"
        color="grey lighten-3">
        <v-icon>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        type="submit"
        class="elevation-0"
        :loading="isLoading || isLoadingCoords || playerLoading"
        color="primary">
        {{ $t('next') }}
        <v-icon right small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import ImageFile from '@/components/features/ImageFile'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SocialProfileForm',
  props: {
    playerLoading: {
      type: Boolean,
      default: false
    },
    sharedUserData: {
      type: Object
    }
  },
  components: {
    ImageFile
  },
  emits: ['handle-form', 'update-nickname'],
  data () {
    return {
      activeProfile: '',
      validForm: false,
      isLoadingCoords: false,
      serverErrors: {},
      payload: {
        picture: '',
        firstName: '',
        lastName: '',
        nickname: '',
        textLocation: '',
        category: 'Individual', // static value
        isActive: true,
        isPublic: true,
        showFollowers: true,
        isChatActive: true
      }
    }
  },
  computed: {
    ...mapGetters({
      tokens: 'hola/tokens',
      isLoading: 'hola/isLoading',
      socialProfiles: 'hola/socialProfiles',
      holaProfile: 'hola/profile'
    })
  },
  watch: {
    sharedUserData: {
      handler () {
        console.log(this.sharedUserData)
        Object.keys(this.sharedUserData).forEach(e => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.payload.hasOwnProperty(e)) {
            this.payload[e] = this.sharedUserData[e]
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.fetchProfiles()
  },
  methods: {
    ...mapActions({
      createProfile: 'hola/CREATE_SOCIAL',
      fetchProfiles: 'hola/GET_SOCIAL_PROFILES'
    }),
    async getLocationPointsByText () {
      this.isLoadingCoords = true
      const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${this.payload.textLocation}&format=json&addressdetails=1&limit=1`)
      const result = await response.json()
      this.isLoadingCoords = false
      return result[0]
    },
    setProfile (params) {
      if (params.nickname === this.activeProfile?.nickname) {
        this.activeProfile = ''
        Object.assign(this.payload, {
          picture: '',
          firstName: '',
          lastName: '',
          nickname: '',
          textLocation: '',
          category: 'Individual',
          isActive: true,
          isPublic: true,
          showFollowers: true,
          isChatActive: true
        })
      } else {
        this.activeProfile = params
        Object.assign(this.payload, params)
      }
    },
    submit () {
      this.serverErrors = {}
      if (this.activeProfile) {
        this.$emit('update-nickname', this.activeProfile.nickname)
        this.$emit('handle-form')
      } else {
        setTimeout(async () => {
          this.$refs.formSocial.validate()
          if (this.validForm) {
            if (!this.payload.picture) {
              this.$toasted.error(this.$t('validationUpload').replace(':label', this.$t('fieldProfilePictureLabel')))
              return
            }
            const requestPayload = {
              ...this.payload
            }

            const location = await this.getLocationPointsByText()
            if (location) {
              requestPayload.location = JSON.stringify({ latitude: location.lat, longitude: location.lon })
            } else {
              requestPayload.location = JSON.stringify({ latitude: 1, longitude: 1 })
            }
            this.createProfile(requestPayload).then(response => {
              this.$emit('update-nickname', response.nickname)
              this.$emit('handle-form')
            }).catch(error => {
              console.log(error)
              if (error.status === 400) {
                this.serverErrors = error.data
              }
            })
          }
        }, 100)
      }
    }
  }
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
// modules
import Home from '@/modules/home/index.vue'
import QuickRules from '@/modules/quick-rules/index.vue'
import PrivacyPolicy from '@/modules/privacy-policy/index.vue'
import TermsOfUse from '@/modules/terms-of-use/index.vue'
import FairPlayPosition from '@/modules/fairplay-position/index.vue'
import LicenseDeadlines from '@/modules/license-deadlines/index.vue'
import YourLicenseWithExtremo from '@/modules/your-license/index.vue'
import CreateLicense from '@/modules/create-license/index.vue'
import FAQ from '@/modules/faq/index.vue'

Vue.use(Router)

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?/',
      name: 'home',
      component: Home
    },
    {
      path: '/:lang?/quick-rules',
      name: 'quick-rules',
      component: QuickRules
    },
    {
      path: '/:lang?/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy
    },
    {
      path: '/:lang?/terms-of-use',
      name: 'terms-of-use',
      component: TermsOfUse
    },
    {
      path: '/:lang?/fairplay-position',
      name: 'fairplay-position',
      component: FairPlayPosition
    },
    {
      path: '/:lang?/faq',
      name: 'faq',
      component: FAQ
    },
    {
      path: '/:lang?/license-deadlines',
      name: 'license-deadlines',
      component: LicenseDeadlines
    },
    {
      path: '/:lang?/agreement',
      name: 'agreement',
      component: YourLicenseWithExtremo
    },
    {
      path: '/:lang?/create-license',
      name: 'create-license',
      component: CreateLicense
    },
    {
      path: '/:lang?/*',
      redirect: { name: 'home' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

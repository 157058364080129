<template>
  <div class="footer">
    <div class="container">
      <div class="footer-wrap">
        <div class="footer-top">
          <ul class="footer-nav">
            <li class="footer-nav__item">
              <router-link :to="$localize({ name: 'quick-rules' })">
                {{ $t('quickRules') }}
              </router-link>
            </li>
            <li class="footer-nav__item">
               <router-link :to="$localize({ name: 'faq' })">
                 {{ $t('faq') }}
               </router-link>
            </li>
<!--            <li class="footer-nav__item">-->
<!--               <router-link :to="$localize({ name: 'home' })">-->
<!--                 {{ $t('media') }}-->
<!--               </router-link>-->
<!--            </li>-->
            <li class="footer-nav__item">
              <router-link :to="$localize({ name: 'fairplay-position' })">
                {{ $t('fairPlayPositionTitle') }}
              </router-link>
            </li>
          </ul>
          <ul class="footer-nav">
            <li class="footer-nav__item">
               <router-link :to="$localize({ name: 'privacy-policy' })">
                 {{ $t('privacyPolicy') }}
               </router-link>
            </li>
            <li class="footer-nav__item">
               <router-link :to="$localize({ name: 'terms-of-use' })">
                 {{ $t('termsOfUse') }}
               </router-link>
            </li>
<!--            <li class="footer-nav__item">-->
<!--              <router-link :to="$localize({ name: 'academy' })">-->
<!--                {{ $t('x7Academy') }}-->
<!--              </router-link>-->
<!--            </li>-->
            <li class="footer-nav__item">
               <router-link :to="$localize({ name: 'agreement' })">
                 {{ $t('licenseWithExtremo7') }}
               </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-bottom">
          <div class="footer-logo">
            <a href="" @click.prevent="changeFooterLogo">
              <img src="../../assets/img/header-logo.png" alt="">
            </a>
          </div>
          <div class="footer-copyright">
            © {{new Date().getFullYear()}} {{ $t('footerCopyright') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data () {
    return {}
  },
  methods: {
    changeFooterLogo () {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' }).catch(() => {})
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 30px 0 50px;
  color: $white;
  background: $ligthBlack;
  @include respondTo(800px){
    padding: 20px 0 30px;
  }
  &-top{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px 0;
    margin-bottom: 40px;
    @include respondTo(800px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
  }
  &-nav {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include respondTo(800px){
      max-width: none;
      width: 100%;
    }
    &__item{
      a{
        display: block;
        text-align: center;
        padding: 5px 0;
        text-decoration: none;
        color: $white;
        font-weight: 500;
        transition: color $trans;
        font-size: 18px;
        line-height: 1.2;
        position: relative;
        overflow: hidden;
        @include respondTo(600px){
          padding: 10px 0;
        }
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translateX(-102%);
          width: 100%;
          height: 2px;
          background: $gold;
          transition: transform $trans;
        }
        @include respondTo(800px){
          font-size: 14px;
        }
        &:hover{
          color: $gold;
          &:after{
            transform: translateX(0);
          }
        }
      }
    }
  }
  &-bottom {
    text-align: center;
  }
  &-logo{
    a{
      margin: 0 auto;
      display: block;
      width: 100px;
      @include respondTo(800px){
        width: 60px;
      }
      &:hover img {
        opacity: 1;
      }
    }
    img{
      opacity: 0.7;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: opacity $trans;
    }
  }
  &-copyright {
    margin-top: 20px;
    border-top: 1px solid rgba(255,255,255, 0.3);
    padding-top: 20px;
    font-size: 16px;
    line-height: 1.1;
    color: rgba(255,255,255,0.6);
    transition: color $trans;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: $white;
    }
    @include respondTo(800px){
      font-size: 14px;
      margin-top: 20px;
    }
  }
}
</style>

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'
import VueHead from 'vue-head'

Vue.use(Vuelidate)
Vue.use(Toasted, {
  duration: 5000
})
Vue.use(VueHead)

export default {
  name: 'FairPlayPosition',
  head: {
    title: function () {
      return {
        inner: 'xtremo 7',
        separator: '-',
        complement: this.$t('failPlayPositionPageTitle')
      }
    }
  }
}

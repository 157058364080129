<template>
  <div class="contact">
    <div class="container">
      <div class="contact-wrap">
        <div class="contact-form__wrap" v-if="step === 1">
          <div class="contact-title">
            <p>{{ $t('contactUsSubTitle1') }}</p>
            <h2>{{ $t('contactUsTitle') }}</h2>
            <p>{{ $t('contactUsSubTitle2') }}</p>
          </div>
          <form action=""
                class="contact-form"
                @submit.prevent="onSubmit">
            <div class="input-row">
              <label :class="emailErrors.length !== 0? 'error': 'valid'">
                <input type="text"
                       autocomplete="off"
                       :placeholder="$t('email')"
                       v-model.trim="payload.email"
                       @input="changeEmailValidation"
                       @blur="$v.payload.email.$touch()">
                <span v-for="(item, index) in emailErrors" :key="index" class="input-errors">{{ item }}</span>
              </label>
            </div>
            <div class="input-row">
              <label :class="textErrors.length !== 0? 'error': 'valid'">
                <textarea v-model.trim="payload.text"
                          :placeholder="$t('typeMessage')"
                          @blur="$v.payload.text.$touch()"/>
                <span v-for="(item, index) in textErrors" :key="index" class="input-errors">{{ item }}</span>
              </label>
            </div>
            <div class="input-row">
              <FileInput v-model="payload.image"
                         withName
                         :label="$t('attachPhoto')"
                         accept="image/*"/>
            </div>
            <div class="form-action">
              <button type="submit"
                      :disabled="this.$v.$invalid">
                <span class="form-action__label">{{ $t('sendButton') }}</span>
                <svg width="10" height="10" aria-hidden="true" focusable="false" data-prefix="far" data-icon="futbol" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"
                     class="svg-inline--fa fa-futbol fa-w-16 fa-2x">
                  <path fill="currentColor"
                        d="M483.8 179.4C449.8 74.6 352.6 8 248.1 8c-25.4 0-51.2 3.9-76.7 12.2C41.2 62.5-30.1 202.4 12.2 332.6 46.2 437.4 143.4 504 247.9 504c25.4 0 51.2-3.9 76.7-12.2 130.2-42.3 201.5-182.2 159.2-312.4zm-74.5 193.7l-52.2 6.4-43.7-60.9 24.4-75.2 71.1-22.1 38.9 36.4c-.2 30.7-7.4 61.1-21.7 89.2-4.7 9.3-10.7 17.8-16.8 26.2zm0-235.4l-10.4 53.1-70.7 22-64.2-46.5V92.5l47.4-26.2c39.2 13 73.4 38 97.9 71.4zM184.9 66.4L232 92.5v73.8l-64.2 46.5-70.6-22-10.1-52.5c24.3-33.4 57.9-58.6 97.8-71.9zM139 379.5L85.9 373c-14.4-20.1-37.3-59.6-37.8-115.3l39-36.4 71.1 22.2 24.3 74.3-43.5 61.7zm48.2 67l-22.4-48.1 43.6-61.7H287l44.3 61.7-22.4 48.1c-6.2 1.8-57.6 20.4-121.7 0z"
                        class=""></path>
                </svg>
                <span class="form-action__loader" v-show="sendContactLoading">
                  <Spinner />
                </span>
              </button>
            </div>
          </form>
        </div>
        <div class="success-message" v-if="step === 2">
          {{ $t('successSendResultMessage1') }}
          <a href="https://privateapp.io/" target="_blank">privateapp.io</a>
          {{ $t('successSendResultMessage2') }}.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from '@/components/atoms/FileInput.vue'
import Spinner from '@/components/atoms/Spinner.vue'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContactForm',
  mixins: [validationMixin],
  data () {
    return {
      payload: {
        image: null,
        email: '',
        text: ''
      },
      step: 1,
      validationErrors: {},
      localLoading: false
    }
  },
  components: {
    FileInput,
    Spinner
  },
  validations: {
    payload: {
      text: {
        required,
        maxLength: maxLength(2048)
      },
      email: {
        email,
        required,
        maxLength: maxLength(256)
      }
    }
  },
  computed: {
    ...mapGetters({
      sendContactLoading: 'contact/sendContactLoading'
    }),
    emailErrors () {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 256))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    textErrors () {
      const error = []
      if (!this.$v.payload.text.$dirty) {
        return error
      }
      if (!this.$v.payload.text.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.text.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 2048))
      }
      if (this.validationErrors.text) {
        this.validationErrors.text.forEach((row) => {
          error.push(row)
        })
      }
      return error
    }
  },
  methods: {
    ...mapActions({
      sendContactMessage: 'contact/sendContactMessage'
    }),
    changeEmailValidation () {
      this.validationErrors.email = []
    },
    onSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.localLoading = true
        const formData = new FormData()
        formData.append('text', this.payload.text)
        formData.append('email', this.payload.email)
        if (this.payload.image) {
          formData.append('image', this.payload.image)
        }

        this.sendContactMessage(formData).then(() => {
          this.step = 2
        }).catch((error) => {
          console.log(error.data.email)
          this.$toasted.error(error.data.detail)
          if (error.data.email) {
            this.validationErrors = { email: [this.$t('validationEmail')] }
            this.$toasted.error(this.$t('validationEmail'))
          }
        }).finally(() => {
          this.localLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  padding: 80px 0;
  background: url('../../../assets/img/home/quick-rules.jpg') center center no-repeat;
  background-size: cover;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
  }
  &-wrap {
    position: relative;
    z-index: 1;
  }
  @include respondTo(600px){
    padding: 20px 0;
  }
  &-title {
    width: calc(52% - 30px);
    text-align: left;
    padding-top: 60px;
    @include respondTo(1000px){
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
      padding-top: 0;
    }
    h2{
      margin: 20px 0;
      font-weight: bold;
      font-size: 42px;
      color: $gold;
      @include respondTo(800px){
        font-size: 20px;
        line-height: 1.1;
        margin: 15px 0;
      }
    }
    p {
      font-size: 18px;
      line-height: 1.2;
      color: $white;
      max-width: 500px;
      @include respondTo(1000px){
        max-width: none;
      }
      @include respondTo(800px){
        font-size: 14px;
      }
    }

    @include respondTo(600px){
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  &-form {
    width: 48%;
    margin-right: 30px;
    @include respondTo(1000px){
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
    &__wrap{
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;
      @include respondTo(1000px){
        flex-direction: column;
      }
    }
    input {
      margin-top: 10px;
      font-family: $mont;
      font-size: 16px;
      color: $white;
      line-height: 20px;
      padding: 8px 15px;
      max-width: 100%;
      min-width: 0;
      display: block;
      height: 40px;
      max-height: none;
      width: 100%;
      background: transparent;
      border: 2px solid $white;
      transition: border-color $trans;
      &::placeholder {
        font-family: $mont;
        font-size: 16px;
        color: $white;
        line-height: 20px;
      }
      &:focus {
        border-color: $gold;
      }
    }
    textarea {
      width: 100%;
      resize: none;
      font-family: $mont;
      font-size: 16px;
      color: $white;
      height: 200px;
      max-height: none;
      margin-top: 10px;
      padding: 8px 15px;
      background: transparent;
      border: 2px solid $white;
      transition: border-color $trans;
      &:focus {
        border-color: $gold;
      }
      &::placeholder {
        font-family: $mont;
        font-size: 16px;
        color: $white;
        line-height: 20px;
      }
      @include respondTo(600px){
        height: 80px;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #cecece;
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: $gold;
        width: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: darkgoldenrod;
        width: 2px;
      }
    }
    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin: 15px 0;

      label {
        display: block;
        width: 100%;
        font-size: 16px;
        line-height: 1.1;
        color: $gold;
        @include respondTo(600px){
          font-size: 14px;
        }
        &.error input,
        &.error textarea {
          border-color: red;
        }
      }

      @include respondTo(600px) {
        flex-direction: column;
      }
    }

    .form-action {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        background: $gold;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.1;
        min-width: 120px;
        transition: background $trans;
        position: relative;
        height: 50px;
        user-select: none;
        svg {
          margin-left: 10px;
        }
        @include respondTo(600px){
          height: 40px;
          font-size: 14px;
        }
        &:hover{
          background: #b99c35;
        }
        &:disabled{
          opacity: 0.5;
          pointer-events: none;
        }
      }
      &__loader{
        position: absolute;
        display: flex;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: $gold;
      }
    }

    .input-errors {
      font-size: 12px;
      line-height: 1.1;
      color: red;
      display: block;
      animation: shake 1 1.5s;
      margin-top: 3px;
    }
  }

  .success-message {
    max-width: 600px;
    font-size: 20px;
    color: $white;
    margin: 0 auto;
    line-height: 1.5;
    text-align: center;
    padding: 30px 0;
    @include respondTo(600px){
      font-size: 14px;
      padding: 20px 0;
    }
    a {
      color: $gold;
      font-weight: 500;
    }
  }
  .form--file{
    @include respondTo(600px) {
      margin: 0;
    }
  }
}
</style>

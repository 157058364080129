<template>
  <div class="trademark">
    <div class="container">
      <div class="trademark-info">
        {{ $t('trademarkInfo') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trademark'
}
</script>

<style lang="scss" scoped>
.trademark {
  padding: 60px 0;
  @include respondTo(600px){
    padding: 30px 0;
  }
  &-info{
    text-align: center;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 500;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
}
</style>

<template>
  <v-form class="form hola-profile"
          v-model="validForm"
          @submit.prevent="handleForm"
          ref="formHola">
    <div class="hola-profile__image hola-profile__item">
      <h3>
        {{ $t('fieldProfilePictureLabel') }}
      </h3>
      <div class="form-img">
        <image-file
          :image="payload.avatarLink"
          v-model="payload.avatarLink">
        </image-file>
        <p class="form-img__subtext">
          {{ $t('holaProfileImageSubtext') }}
        </p>
      </div>
    </div>
    <div class="hola-profile__setting hola-profile__item">
      <h3>
        {{ $t('profileSetting') }}
      </h3>
      <v-row class="mb-1 mt-1">
        <v-col cols="12" sm="6" class="pt-0 pb-0">
          <div class="form-field">
            <div class="form-field__label">
              {{ $t('fieldFirstNameLabel') }}
            </div>
            <div class="form-field__body">
              <v-text-field
                v-model="payload.firstName"
                :error-messages="serverErrors.firstName"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  :rules="defaultRules"
                  name="name"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldLastNameLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="payload.lastName"
                  :rules="defaultRules"
                  :error-messages="serverErrors.lastName"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  name="name"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" class="pt-0 pb-0">
            <div class="form-field mb-5">
              <div class="form-field__label">
                {{ $t('fieldEmailLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model.trim="payload.email"
                  :error-messages="serverErrors.email"
                  class="mt-0 pt-0"
                  :rules="emailRules"
                  prepend-inner-icon="mdi-pencil"
                  name="email"
                  type="text"
                />
                <small style="font-size: 12px; line-height: 1.1; display: block; color: #999">
                  {{ $t('emailWarning') }}
                </small>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldNicknameLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="payload.nickname"
                  :error-messages="serverErrors.nickname"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  :rules="websiteNameRules"
                  name="nickname"
                  type="text"
                />
              </div>
            </div>
          </v-col>
        </v-row>
    </div>
    <div class="hola-profile__phone hola-profile__item">
      <h3>
        {{ $t('registeredPhoneNumber') }}
      </h3>
      <div class="form-field">
        <div class="form-field__body">
          <v-text-field
            disabled
            v-model="payload.phone"
            class="mt-2 pt-0"
            prepend-inner-icon="mdi-pencil"
            :rules="defaultRules"
            name="phone"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <v-btn
        class="elevation-0"
        @click.prevent="$emit('change-step', 1)"
        color="grey lighten-3">
        <v-icon>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        type="submit"
        class="elevation-0"
        :loading="isLoading"
        :disabled="uploadImageLoading"
        color="primary">
        {{ $t('next') }}
        <v-icon right small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import ImageFile from '@/components/features/ImageFile'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HolaProfileForm',
  props: {
    sharedUserData: {
      type: Object
    }
  },
  components: {
    ImageFile
  },
  emits: ['handle-form', 'change-step'],
  data () {
    return {
      validForm: false,
      serverErrors: {},
      uploadImageLoading: false,
      payload: {
        phone: '',
        avatarLink: '',
        firstName: '',
        lastName: '',
        nickname: '',
        email: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'hola/profile',
      isLoading: 'hola/isLoading'
    })
  },
  watch: {
    'payload.avatarLink' () {
      if (this.payload.avatarLink && this.payload.avatarLink.indexOf('base64') > 0) {
        this.uploadImageLoading = true
        this.updateImage({ file: this.payload.avatarLink }).then(response => {
          this.payload.avatarLink = response.imgLink
        }).finally(() => {
          this.uploadImageLoading = false
        })
      }
    }
  },
  created () {
    this.fetchProfile().then(() => {
      Object.assign(this.payload, this.profile)

      Object.keys(this.sharedUserData).forEach(e => {
        if (this.payload[e] && !this.payload[e]) {
          this.payload[e] = this.sharedUserData[e]
        }
      })
    })
  },
  methods: {
    ...mapActions({
      fetchProfile: 'hola/GET_PROFILE',
      updateProfile: 'hola/UPDATE_PROFILE',
      updateImage: 'hola/UPDATE_IMAGE'
    }),
    handleForm () {
      this.serverErrors = {}
      setTimeout(() => {
        this.$refs.formHola.validate()
        if (this.validForm) {
          if (!this.payload.avatarLink) {
            this.$toasted.error(this.$t('validationUpload').replace(':label', this.$t('fieldProfilePictureLabel')))
            return
          }
          const requestPayload = {
            firstName: this.payload.firstName,
            lastName: this.payload.lastName,
            nickname: this.payload.nickname,
            email: this.payload.email
          }
          if (this.payload.avatarLink) requestPayload.avatarLink = this.payload.avatarLink
          this.updateProfile(requestPayload).then(() => {
            this.$emit('handle-form', {
              phone: this.profile.phone,
              email: this.profile.email,
              firstName: this.profile.firstName,
              lastName: this.profile.lastName,
              nickname: this.profile.nickname,
              holaId: this.profile.userId
            })
            this.$emit('change-step', 3)
          }).catch(error => {
            if (error.status === 404) {
              const { field, type } = error.data.data
              this.$set(this.serverErrors, field, this.$t(`${field}_${type}`))
            }
          })
        }
      }, 100)
    }
  }
}
</script>

import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_LIVE_LEAGUE_API}`

export default {
  checkLicenseSlug ({ slug }) {
    return apiReq.get(`${BASE_URL}/season-licences/check-slug/?slug=${slug}`)
  },
  checkUsername ({ username }) {
    return apiReq.get(`${BASE_URL}/users/check-username/?username=${username || ''}`)
  },
  checkUserPhone (phone) {
    return apiReq.get(`${BASE_URL}/users/check-phone/?phone=${encodeURIComponent(phone) || ''}`)
  }
}


const state = {
  step: 1
}

const getters = {
  step: state => state.step
}

const actions = {
}

const mutations = {
  changeSponsorLeaguesStep (state, count) {
    state.step = count
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <div class="header">
    <div class="container">
      <div class="header-wrap">
        <div class="header-left">
          <div class="header-logo">
            <router-link
              :to="$localize({ name: 'home' })">
              <img src="../../assets/img/header-logo.png" alt="">
            </router-link>
          </div>
        </div>
        <div class="header-right">
          <div class="header-right">
            <div class="header-language">
              <TooltipLayout
                :maxWidth="160"
                position="left">
                <template slot="icon">
                  <img class="flag"
                       :src="`/locales-ico/${currentLanguage.urlPrefix}.png`"
                       :alt="currentLanguage.name"
                       :title="currentLanguage.name">
                </template>
                <ul>
                  <li v-for="item in languages"
                      :key="item.id" class="column">
                    <a @click.prevent="changeLanguage(item)"
                       :title="item.name"
                       v-if="item.urlPrefix !== currentLanguage.urlPrefix">
                    <span class="language-item">
                        <img :alt="item.name"
                             :src="`/locales-ico/${item.urlPrefix}.png`"
                             class="flag"/>
                      <span class="language__text">
                        {{ item.name }}
                      </span>
                    </span>
                    </a>
                  </li>
                </ul>
              </TooltipLayout>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipLayout from '@/components/atoms/TooltipLayout.vue'
import HeaderMenu from '@/components/features/HeaderMenu.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'AppHeader',
  components: {
    TooltipLayout,
    HeaderMenu
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage'
    ])
  },
  methods: {
    ...mapMutations({
      changeShowMenu: 'system/changeShowMenu'
    }),
    changeLanguage (language) {
      this.$setLanguage(language.code)
      setTimeout(() => {
        window.location.reload()
      }, 800)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  z-index: 10;
  position: relative;
  &-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
  }
  &-logo {
    a {
      display: block;
      width: 100px;
      &:hover img{
        transform: scale(0.95);
      }
      @include respondTo(1200px){
        width: 60px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform $trans;
      }
    }
  }
  &-language {
    z-index: 3;
  }
  &-lang{
    &__item{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &__name{
      margin-left: 10px;
    }
  }
  &-menu{
    margin-left: 15px;
    z-index: 3;
    &__links{
      padding: 15px 15px 5px;
      a{
        display: block;
        width: 48%;
        filter: grayscale(100);
        transition: filter $trans;
        &:hover{
          filter: grayscale(0);
        }
      }
    }
  }
}

</style>

<template>
  <v-form class="form agreements"
          v-model="validForm"
          @submit.prevent="submit"
          ref="form">
    <h2 class="mb-3">{{ $t('infoBlockCreateLicenseTitle') }}</h2>
    <p class="mb-9" style="line-height: 1.3;">
      {{ $t('infoBlockCreateLicenseDescription') }}
    </p>
    <v-row class="social-profile__item mb-4">
      <v-col cols="12">
        <div class="form-field">
          <div class="form-field__label">
            {{ $t('fieldElectronicSignature') }}
          </div>
          <div class="form-field__body">
            <v-text-field
              v-model.trim="payload.signature"
              class="mt-0 pt-0"
              prepend-inner-icon="mdi-pencil"
              :rules="defaultRules"
              name="signature"
              hide-details="auto"
              type="text"
            />
          </div>
        </div>
        <div class="form-field">
          <div class="form-field__body">
            <v-checkbox
              class="mt-0"
              v-model="payload.agreementAccepted"
              :rules="defaultRules"
              :label="$t('fieldCheckAgreements')"
            ></v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-3">
      <v-btn
        class="mr-4 elevation-0"
        @click.prevent="$emit('change-step')"
        color="grey lighten-3">
        <v-icon>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        type="submit"
        class="elevation-0"
        :loading="isLoading"
        color="primary">
        {{ $t('save') }}
        <v-icon right>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <div class="license-errors mt-3">
      <div class="license-errors__item" v-for="item in serverErrors" :key="item.key">
        <i>
          {{ $t(item.key) }}:
        </i>
        <p>
          {{ item.value }}
        </p>
      </div>
    </div>
  </v-form>
</template>
<script>
export default {
  name: 'LicenceAgreement',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    serverErrors: {}
  },
  emits: ['update'],
  data () {
    return {
      validForm: false,
      payload: {
        signature: '',
        agreementAccepted: false
      }
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate()
      if (this.validForm) {
        this.$emit('update', this.payload)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.agreements-container {
  width: 100%;
  height: 35vh;
  min-height: 250px;
  margin-bottom: 30px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid $green;
}

.footer-item {
  &__title {
    font-size: 26px;
    line-height: 34px;
    font-weight: bold;
    margin-bottom: 30px;
    @include respondTo(1000px) {
      font-size: 20px;
      line-height: 25px;
    }
    @include respondTo(500px) {
      font-size: 20px;
      line-height: 24px;
    }

    h5 {
      margin-top: 15px;
    }
  }

  &__info {

    p {
      font-weight: normal;
      margin: 15px 0;
      font-size: 16px;
      line-height: 26px;

      @include respondTo(1000px) {
        font-size: 16px;
        line-height: 22px;
      }

      @include respondTo(500px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    span {
      display: block;
      font-size: 16px;
      line-height: 18px;
      margin: 15px 0 0;
      font-weight: 500;
      color: red;

      @include respondTo(1000px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    h4 {
      font-size: 18px;
      line-height: 20px;

      @include respondTo(1000px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    h5 {
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0;

      @include respondTo(1000px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    h6 {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

</style>
